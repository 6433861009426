import React from "react";
import "./AppLoader.scss";
import SectionLoader from "./SectionLoader";

function AppLoader() {
  const modalOpen = document.querySelector("body")?.classList.contains("modal-open");

  return (
    <div
      className={`text-center ${
        modalOpen ? "app-loader_modalOpen" : "app-loader"
      } position-fixed w-100 d-flex align-items-center justify-content-center`}
    >
      <SectionLoader />
    </div>
  );
}

export default AppLoader;
