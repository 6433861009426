import React from "react";

function AccessDeniedRoute() {
  return (
    <div className="container my-5">
      <div className="col-md-6 mx-auto">
        <h1>403</h1>
        <p>Oops! Access Denied.</p>
      </div>
    </div>
  );
}

export default AccessDeniedRoute;
