import { useAppSelector } from "core/helpers/index";
import { UserPermissions } from "core/types/enums/PermissionsEnum";
import useAuth from "./useAuth";

const useUserRole = () => {
  const { userCanViewRoute } = useAuth();
  const { currentUser } = useAppSelector((state) => state.app);

  const userPermissions = currentUser?.rolePermissions || undefined;

  const permissionGranted = (allowedPermissions: UserPermissions[]) =>
    userCanViewRoute(allowedPermissions, userPermissions);

  return {
    permissionGranted,
    currentUser,
  };
};

export default useUserRole;
