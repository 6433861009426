/* eslint-disable @typescript-eslint/return-await */
import { useAppDispatch } from "core/helpers/index";
import apiSvc from "core/services/ApiService";
import { useNavigate } from "react-router-dom";
import {
  setAccessToken,
  setCurrentUser,
  setError,
  setLoader,
  setNotification,
} from "store/slices/appSlicer";
import { publicLinks } from "constants/AppLinks";
import { ResponseType } from "axios";
import useLSActions from "./useLSActions";
import { insteadError } from "../../constants/messages";
import { ILsAuth } from "../../core/types/base/IToken";

type TProps = {
  url: string;
  method: "GET" | "POST" | "PUT" | "DELETE" | "QUERY";
  params?: { [key: string]: any } | null;
  body?: any;
  successMessage?: string;
  errorMessage?: string;
  isProtected?: boolean;
  useLoader?: boolean;
  headers?: any;
  tokenValue?: string;
  responseType?: ResponseType;
  ignoreEndLoader?: boolean;
};

const useBaseAction = () => {
  const dispatch = useAppDispatch();
  const { getFromLs, removeItemFromLs, defineCustomError } = useLSActions();
  const navigate = useNavigate();

  const generateRequest = async ({
    url,
    method,
    body,
    params,
    isProtected = true,
    tokenValue,
    responseType,
  }: TProps) => {
    const lsToken = (await getFromLs<ILsAuth>("token")) || ({} as ILsAuth);
    const api = apiSvc({ token: lsToken.token || tokenValue || "", isProtected });
    const options = {
      params: params || {},
      responseType: responseType || undefined,
    };

    switch (method) {
      case "GET":
      case "QUERY":
        return await api.get(url, options);
      case "POST":
        return await api.post(url, body || undefined, options);
      case "PUT":
        return await api.put(url, body || undefined, options);
      case "DELETE":
        return await api.delete(url, {
          data: body,
        });
      default:
        return await api.get(url, options);
    }
  };

  const request = async (
    {
      url,
      method,
      body,
      successMessage,
      errorMessage,
      params,
      isProtected,
      useLoader = true,
      tokenValue,
      headers,
      responseType,
      ignoreEndLoader,
    }: TProps,
    successCallback?: (data?: any) => Promise<void>,
    errorCallback?: (error?: any) => void,
    finallyCallback?: () => Promise<void>,
  ) => {
    if (useLoader) dispatch(setLoader(true));
    try {
      const response = await generateRequest({
        url,
        method,
        body,
        params,
        isProtected,
        tokenValue,
        headers,
        responseType,
      });
      const data = response ? response.data : null;

      if (successCallback) await successCallback(data);
      if (useLoader && !ignoreEndLoader) dispatch(setLoader(false));
      dispatch(setError(null));
      if (successMessage) dispatch(setNotification({ type: "success", message: successMessage }));
      return data;
    } catch (error: any) {
      if (navigator.onLine) {
        let responseErrMsgs =
          error?.response?.data?.errors ||
          error?.response?.data?.detail ||
          error?.response?.data?.details ||
          null;
        // eslint-disable-next-line no-nested-ternary
        responseErrMsgs = responseErrMsgs
          ? Array.isArray(responseErrMsgs)
            ? Object.entries(responseErrMsgs).map(([, value]) => value)
            : responseErrMsgs
          : "Something went wrong!";

        // eslint-disable-next-line no-nested-ternary
        responseErrMsgs = Array.isArray(responseErrMsgs)
          ? responseErrMsgs.map((err) => err?.description || err).join(", ")
          : typeof responseErrMsgs === "object"
          ? Object.entries(responseErrMsgs)
              .map(([, value]) => value)
              .join(", ")
          : responseErrMsgs;
        // responseErrMsgs = Array.isArray(responseErrMsgs)
        //   ? responseErrMsgs.map((err) => err?.description || err).join(", ")
        //   : responseErrMsgs;

        const httpStatusCode = error?.response?.status || null;

        if (httpStatusCode === 401) {
          dispatch(setCurrentUser(null));
          dispatch(setAccessToken(""));
          removeItemFromLs("token");
          navigate(`/${publicLinks.singin}`);
        }

        if (httpStatusCode === 403) {
          navigate(`/${publicLinks.forbidden}`);
        }

        if (errorCallback) await errorCallback(error);
        dispatch(setError(error?.response?.data));
        if (useLoader) dispatch(setLoader(false));
        if (insteadError.includes(error?.response?.data?.instance)) {
          dispatch(
            setNotification({
              type: "error",
              message:
                defineCustomError(error?.response.data?.instance) ??
                (errorMessage || responseErrMsgs),
            }),
          );
        } else {
          dispatch(
            setNotification({
              type: "error",
              message: errorMessage || responseErrMsgs,
            }),
          );
        }
      }
      dispatch(setError({ status: error.request.status, message: error.message }));
      throw error;
    } finally {
      if (finallyCallback) await finallyCallback();
      dispatch(setLoader(false));
    }
  };

  return {
    request,
  };
};

export default useBaseAction;
