import Qs from "qs";
import axios, { AxiosRequestConfig, AxiosRequestHeaders } from "axios";

import { appConfig } from "constants/index";

type TAxiosConfigOptions = {
  token: string;
  isProtected?: boolean;
};

export const axiosConfig = () => {
  const headers = {
    "Content-type": "application/json",
  } as AxiosRequestHeaders;

  return {
    withCredentials: false,
    baseURL: appConfig.baseApiUrl,
    headers,
    paramsSerializer: (params: any) =>
      Qs.stringify(params, { arrayFormat: "repeat", skipNulls: true }),
  } as AxiosRequestConfig;
};

const apiSvc = (options?: TAxiosConfigOptions) => {
  const instance = axios.create(axiosConfig());

  instance.interceptors.request.use(
    async (config) => {
      const { isProtected, token } = options || {};
      const originalConfig = config as any;
      if (isProtected) {
        originalConfig.headers.Authorization = `Bearer ${token}`;
      }
      originalConfig.headers.tenant = `root`;

      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  return instance;
};

export default apiSvc;
