import { useEffect, useState } from "react";
import { useBaseAction } from "hooks/core";
import { ICustomReactSelectOption } from "core/types/elements/ICustomReactSelectOption";

type TProps = {
  fetchOnLoad: boolean;
};

const useUserRoles = ({ fetchOnLoad }: TProps) => {
  const { request } = useBaseAction();
  const [roles, setRoles] = useState<ICustomReactSelectOption[]>([] as ICustomReactSelectOption[]);

  const getRoles = async () => {
    const data = (await request({
      method: "GET",
      url: "/roles",
      useLoader: true,
    })) as string[];
    setRoles(
      data.map((role) => ({
        value: role,
        label: role,
      })) || [],
    );
  };

  useEffect(() => {
    if (fetchOnLoad) getRoles();
  }, []);

  return {
    getRoles,
    roles,
  };
};

export default useUserRoles;
