enum UserPermissions {
  "Company.Browse" = "Company.Browse",
  "Company.Manage" = "Company.Manage",
  "Calendar.Browse" = "Calendar.Browse",
  "Calendar.Manage" = "Calendar.Manage",
  "Contact.Browse" = "Contact.Browse",
  "Contact.Manage" = "Contact.Manage",
  "Item.Browse" = "Item.Browse",
  "Quote.Browse" = "Quote.Browse",
  "User.Browse" = "User.Browse",
  "User.ManageOthers" = "User.ManageOthers",
  "User.ManageSelf" = "User.ManageSelf",
  "Vehicle.Browse" = "Vehicle.Browse",
  "Vehicle.Manage" = "Vehicle.Manage",
  "Equipment.Manage" = "Equipment.Manage",
  "Equipment.Browse" = "Equipment.Browse",
  "WorkOrder.AssignedTech" = "WorkOrder.AssignedTech",
  "WorkOrder.Browse" = "WorkOrder.Browse",
  "WorkOrder.CanDoWorkOrders" = "WorkOrder.CanDoWorkOrders",
  "WorkOrder.Manage" = "WorkOrder.Manage",
  "Settings.ManageTenant" = "Settings.ManageTenant",
  "Settings.ManageUser" = "Settings.ManageUser",
}

export {
  // eslint-disable-next-line import/prefer-default-export
  UserPermissions,
};
