import { UserPermissions } from "core/types/enums/PermissionsEnum";
import { technichianToolLinks } from "constants/AppLinks";

const navbarMenuItems = [
  {
    type: "link",
    title: "Sales",
    link: `/${technichianToolLinks.companySales}`,
    permissions: [UserPermissions["User.ManageOthers"]],
  },
  {
    type: "link",
    title: "Schedule",
    link: `/${technichianToolLinks.schedule}`,
    permissions: [UserPermissions["WorkOrder.Browse"]],
  },
  {
    type: "link",
    title: "Work Orders",
    link: `/${technichianToolLinks.workOrdersList}`,
    permissions: [UserPermissions["WorkOrder.Browse"]],
  },
  {
    type: "link",
    title: "Quotes",
    link: `/${technichianToolLinks.quotesList}`,
    permissions: [UserPermissions["Quote.Browse"]],
  },
  {
    type: "link",
    title: "Companies",
    link: `/${technichianToolLinks.companyList}`,
    permissions: [UserPermissions["Company.Browse"]],
  },
  {
    type: "dropdown",
    title: "Manage Entities",
    dropdownItems: [
      {
        title: "Equipment Makes",
        link: `/${technichianToolLinks.equipmentMakesList}`,
      },
      {
        title: "Equipment Models",
        link: `/${technichianToolLinks.equipmentModelsList}`,
      },
      {
        title: "Equipment Types",
        link: `/${technichianToolLinks.equipmentTypesList}`,
      },
      {
        title: "Vehicles",
        link: `/${technichianToolLinks.vehiclesList}`,
      },
    ],
    permissions: [UserPermissions["Equipment.Browse"]],
  },
];

export default navbarMenuItems;
