const appConfig = {
  baseApiUrl: process.env.REACT_APP_API_URL,
  userRoles: {
    admin: "Admin",
    technician: "Technician",
  },
  itemsPerPage: 25,
  splitMultipleQueryValueBy: ",",
};

export default appConfig;
