import React, { FC } from "react";

interface IProps {
  spinnerClass?: string;
}

const SectionLoader: FC<IProps> = ({ spinnerClass }) => {
  return (
    <div className={`spinner-border text-primary ${spinnerClass ?? ""}`} role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  );
};

export default SectionLoader;
