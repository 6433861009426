import React from "react";
import "./AppLoader.scss";
import SectionLoader from "./SectionLoader";

function ModalLoader() {
  return (
    <div className="text-center d-flex align-items-center justify-content-center">
      <SectionLoader spinnerClass="spinner-border-modal text-white" />
    </div>
  );
}

export default ModalLoader;
