import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import store from "store";
import { Provider } from "react-redux";
import "bootstrap";
import reportWebVitals from "./reportWebVitals";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import "bootswatch/dist/lux/bootstrap.min.css";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <Router>
    <Provider store={store}>
      <App />
    </Provider>
  </Router>,
);

reportWebVitals();
serviceWorkerRegistration.register();
