import { useAppDispatch, useAppSelector } from "core/helpers";
import { IUser, TFormChangeEmail, TFormUser } from "core/types/base/IUser";
import { useBaseAction, useHandleModals } from "hooks/core";
import { useEffect, useState } from "react";
import { setCurrentUser } from "store/slices/appSlicer";
import useUserRoles from "./useUserRoles";

type TProps = {
  fetchOnLoad: boolean;
};

const actions = [
  "editUser",
  "config",
  "addUser",
  "warningStatus",
  "warningPassword",
  "changeRole",
  "sendInvite",
  "changeEmail",
] as const;

const useUserManagement = ({ fetchOnLoad }: TProps) => {
  const { request } = useBaseAction();
  const dispatch = useAppDispatch();
  const { currentUser } = useAppSelector((state) => state.app);
  const { roles } = useUserRoles({ fetchOnLoad });
  const { modals, toggleModal, setSelectedEntity, selectedEntity, setModalTitle, modalTitle } =
    useHandleModals<IUser, typeof actions[number]>({
      modalsKeys: actions,
    });

  const [users, setUsers] = useState<IUser[]>([] as IUser[]);

  const getUsers = async (filter?: string) => {
    const response = (await request({
      method: "GET",
      url: `Users${filter ? `?orderby=${filter}` : ""}`,
      useLoader: true,
    })) as IUser[];
    setUsers(response);
  };

  useEffect(() => {
    if (fetchOnLoad) getUsers();
  }, []);

  const getUserById = async (userId: string) => {
    const user = (await request({
      method: "GET",
      url: `Users/${userId}`,
      useLoader: true,
    })) as IUser;
    setSelectedEntity(user);
    return user;
  };

  const resetStates = () => {
    toggleModal({ hideAll: true });
    setModalTitle("");
    setSelectedEntity(null);
  };

  const createEditUser = async (user: TFormUser, loadList = true) => {
    const { firstName, lastName, email, role, phoneNumber } = user;
    const { id } = selectedEntity || {};
    const isUpdate = modals.editUser;

    const updatedUser: IUser = await request({
      method: isUpdate ? "PUT" : "POST",
      url: `Users${isUpdate ? `/${id}` : ""}`,
      body: {
        firstName,
        lastName,
        email,
        role,
        phoneNumber,
      },
      successMessage: `${firstName} ${lastName} has been ${isUpdate ? "changed" : "created"}`,
      useLoader: true,
    });

    if (currentUser?.id === id) dispatch(setCurrentUser(updatedUser));
    if (loadList) await getUsers();

    toggleModal({ hideAll: true });
  };

  const toggleUserStatus = async (user: IUser) => {
    const { id: userId, isActive, firstName, lastName } = user;
    await request({
      method: "POST",
      url: `Users/${userId}/ToggleStatus`,
      body: { userId, activateUser: !isActive },
      successMessage: `${firstName} ${lastName} has been ${isActive ? "deactivated" : "activated"}`,
      useLoader: true,
    });
    resetStates();
    await getUsers();
  };

  const changeUserEmail = async (user: TFormChangeEmail, loadList = true) => {
    const { id: userId, email } = user;
    await request({
      method: "POST",
      url: `Users/${userId}/ChangeEmail`,
      body: { email },
      successMessage: `Change email request has been sent to ${email}`,
      useLoader: true,
    });
    resetStates();
    if (loadList) await getUsers();
  };

  const forgotPassword = async (user: IUser) => {
    const { email } = user;
    await request({
      method: "POST",
      url: `Users/ForgetPassword`,
      body: { email },
      successMessage: `Reset link has been sent to ${email}`,
      useLoader: true,
    });
    resetStates();
    await getUsers();
  };

  const sendEmailInvite = async (user: IUser) => {
    const { id, email } = user;
    await request({
      method: "POST",
      url: `Users/ResendEmailConfirmation?userId=${id}`,
      successMessage: `Invitation link link has been sent to ${email}`,
      useLoader: true,
    });
    resetStates();
    await getUsers();
  };

  const handleUserAction = (
    modalKey: string,
    user: IUser,
    event?: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    if (event) event.preventDefault();

    let title = "";

    switch (modalKey) {
      case "warningStatus":
        title = `Are you sure that you want to ${user.isActive ? "deactivate" : "re-activate"} ${
          user.firstName
        } ${user.lastName} ?`;
        break;
      case "warningPassword":
        title = `Are you sure that you want to reset password for ${user.firstName} ${user.lastName} ?`;
        break;
      case "sendInvite":
        title = `Are you sure that you want to send invite for ${user.firstName} ${user.lastName} ?`;
        break;
      case "addUser":
        title = "Add new User";
        break;
      case "config":
        title = "Configurable items";
        break;
      case "changeEmail":
        title = `Change email for ${user.firstName} ${user.lastName}`;
        break;
      case "editUser":
        title = `Edit ${user.firstName} ${user.lastName}`;
        break;
      case "changeRole":
        title = `Change Role(s) for ${user.firstName} ${user.lastName}`;
        break;

      default:
        break;
    }
    setSelectedEntity(user);
    setModalTitle(title);
    toggleModal({ key: modalKey, value: true });
  };

  const handleConfirmUserAction = () => {
    const { warningStatus, warningPassword, sendInvite } = modals;

    if (warningStatus) {
      toggleUserStatus(selectedEntity!);
    } else if (warningPassword) {
      forgotPassword(selectedEntity!);
    } else if (sendInvite) {
      sendEmailInvite(selectedEntity!);
    }
  };

  return {
    getUsers,
    createEditUser,
    users,
    modals,
    toggleModal,
    toggleUserStatus,
    handleUserAction,
    selectedEntity,
    modalTitle,
    handleConfirmUserAction,
    forgotPassword,
    roles,
    getUserById,
    changeUserEmail,
  };
};

export default useUserManagement;
