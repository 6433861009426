import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import app from "store/slices/appSlicer/index";
import data from "store/slices/dataSlicer/index";
import filter from "store/slices/filterSlicer/index";

const store = configureStore({
  reducer: {
    app,
    data,
    filter,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export default store;
