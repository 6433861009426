import React from "react";
import "./App.scss";
import AppRoutes from "navigation/AppRoutes";
import Navbar from "components/common/Navbar/Navbar";
import Footer from "components/common/Footer/Footer";
import AppWrapper from "./navigation/AppWrapper";
import { clearCaches } from "./serviceWorkerRegistration";

function App() {
  React.useEffect(() => {
    if (navigator.onLine) {
      clearCaches().then(async () => {
        const cachesKeys = await caches.keys();
        if (cachesKeys.length) {
          window.location.reload();
        }
      });
    }
  }, []);
  return (
    <>
      <Navbar />
      <AppWrapper>
        <AppRoutes />
      </AppWrapper>
      <Footer />
    </>
  );
}

export default App;
