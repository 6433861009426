const useLocalStorage = () => {
  const asyncStorageSetItem = async (key: string, value: string): Promise<boolean> => {
    try {
      await localStorage.setItem(key, value);
      return true;
    } catch (exception) {
      return false;
    }
  };

  const asyncStorageGetItem = async <T,>(key: string): Promise<T | null> => {
    try {
      const retrievedItem = await localStorage.getItem(key);
      if (retrievedItem) return JSON.parse(retrievedItem) as T;
      return null;
    } catch (error) {
      return null;
    }
  };

  const asyncStorageRemoveItem = async (key: string): Promise<boolean> => {
    try {
      await localStorage.removeItem(key);
      return true;
    } catch (exception) {
      return false;
    }
  };

  return {
    asyncStorageSetItem,
    asyncStorageGetItem,
    asyncStorageRemoveItem,
  };
};

export default useLocalStorage;
