import React, { FC, ReactNode, Suspense, useEffect } from "react";

import AppLoader from "components/common/AppLoader/AppLoader";
import AppNotification from "components/common/AppNotification/AppNotification";
import { useAppSelector } from "core/helpers/index";
import { useAuth } from "hooks/core/index";
import { ErrorBoundary } from "react-error-boundary";
import ErrorBoundaryFallback from "components/common/Error/ErrorBoundaryFallback";

type TProps = {
  children: ReactNode;
};

const AppWrapper: FC<TProps> = ({ children }) => {
  const { appInitialized } = useAppSelector((state) => state.app);
  const { checkIfLoggedInUserIsValid } = useAuth();

  useEffect(() => {
    checkIfLoggedInUserIsValid();
  }, []);

  return (
    <>
      {/* fallback={<AppLoader />} */}
      <Suspense fallback={<AppLoader />}>
        <ErrorBoundary fallback={<ErrorBoundaryFallback />}>
          {appInitialized ? children : null}
        </ErrorBoundary>
      </Suspense>
      {/* {showLoader && <AppLoader />} */}
      <AppNotification />
    </>
  );
};

export default AppWrapper;
