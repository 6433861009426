import { useEffect, useRef, useState } from "react";

export type TToggleAction<T> = {
  key?: string;
  value?: boolean;
  hideAll?: boolean;
  selectedItem?: T | null;
  headerText?: string;
};

type TProps = {
  modalsKeys: readonly string[];
  title?: string;
};

const useHandleModals = <T extends Object, P extends string>({ modalsKeys, title }: TProps) => {
  const modalsFields = useRef<{ [key in P]: any }>({} as any);
  const [selectedEntity, setSelectedEntity] = useState<T | null>(null);
  const [modals, setModals] = useState<{ [key in P]: any }>({} as any);
  const [modalTitle, setModalTitle] = useState<string>(title || "");

  const initModalsFields = (keys: readonly string[]) => {
    const objOfFields = keys.reduce(
      (acc: any, curr: any) => ({
        ...acc,
        [curr]: false,
      }),
      {},
    );
    modalsFields.current = objOfFields;
    setModals(objOfFields);
  };

  useEffect(() => {
    initModalsFields(modalsKeys);
  }, []);

  const toggleModal = ({ key, value, hideAll, selectedItem, headerText }: TToggleAction<T>) => {
    if (hideAll) {
      setModals(modalsFields.current);
      setSelectedEntity(null);
    } else if (key) {
      setModals({
        ...modalsFields.current,
        [key]: value,
      });
    }
    if (headerText) {
      setModalTitle(headerText);
    }
    if (selectedItem) {
      setSelectedEntity(selectedItem);
    }
  };

  return {
    selectedEntity,
    toggleModal,
    setSelectedEntity,
    modals,
    modalTitle,
    setModalTitle,
  };
};

export default useHandleModals;
