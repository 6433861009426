import { useBaseAction } from "hooks/core";
import { useEffect, useState } from "react";
import { API } from "constants/RestApi";
import { QBSync } from "core/types/entities/QB";

const useHandleConnection = () => {
  const qbunAuthErrorCode = "QuickBooksAuthorizationRequiredException";
  const { request } = useBaseAction();
  const [qbAuthUrl, setQbAuthUrl] = useState<string>("");
  const [showModal, setShowModal] = useState<boolean>(false);
  const [statuses, setStatuses] = useState<QBSync | null>(null);

  const getAuthUrl = async () => {
    const url = await request({
      url: "QuickBooks/AuthenticationUrl",
      method: "GET",
      useLoader: true,
    });
    setQbAuthUrl(url);
    setShowModal(true);
  };

  const runAuthCallback = async (code: string, state: string, realmId: string) => {
    await request({
      url: "QuickBooks/AuthCallback",
      method: "GET",
      params: {
        code,
        state,
        realmId,
      },
      useLoader: true,
    });
  };

  const testReq = async () => {
    await request({
      url: "Dev/QbQueryTest?query=select%20%2A%20from%20CompanyInfo&throwAuthRequired=true",
      method: "GET",
      useLoader: true,
    });
  };

  const checkQBStatuses = (statusList: QBSync) => {
    Object.entries(statusList).forEach(([, value]) => {
      if (value?.status === "QuickBooksAuthorizationRequired") {
        setShowModal(true);
      } else {
        setShowModal(false);
      }
    });
  };

  const getQBStatuses = async () => {
    const statusList = (await request({
      url: API.QUICKBOOKS_STATUS,
      method: "GET",
      useLoader: false,
    })) as QBSync;
    if (statusList) {
      setStatuses(statusList);
    }
  };

  const appGotQBUauthErr = async () => {
    if (showModal) {
      await getAuthUrl();
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (statuses) {
      checkQBStatuses(statuses);
    }
  }, [statuses]);

  useEffect(() => {
    appGotQBUauthErr();
  }, [showModal]);

  return {
    getAuthUrl,
    testReq,
    qbunAuthErrorCode,
    appGotQBUauthErr,
    showModal,
    qbAuthUrl,
    runAuthCallback,
    getQBStatuses,
    statuses,
  };
};

export default useHandleConnection;
