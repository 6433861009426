import React, { FC, ReactNode, useEffect } from "react";
import { useLocation } from "react-router-dom";

import useHandleConnection from "hooks/qb/useHandleConnection";
import { ILocalTimer } from "core/types/entities/IExpense";
import useLocalStorage from "hooks/common/useLocalStorage";
import { useAppDispatch } from "core/helpers";
import { setTimer } from "store/slices/appSlicer";
import QBUnauthWarningModal from "../Modals/QBUnauthWarningModal";
import "./MainLayout.scss";

type TProps = {
  children: ReactNode;
};

const MainLayout: FC<TProps> = ({ children }) => {
  const { showModal, qbAuthUrl, getQBStatuses } = useHandleConnection();
  const location = useLocation();
  const { asyncStorageGetItem } = useLocalStorage();
  const dispatch = useAppDispatch();

  const clearModalBackdrop = () => {
    const modal = document.getElementsByClassName("modal-backdrop");
    if (modal.length) {
      modal[0].remove();
    }
  };

  const getLocalTimer = async () => {
    const localTimer = await asyncStorageGetItem<ILocalTimer | null>("timer");
    if (localTimer) {
      dispatch(setTimer(localTimer));
    }
  };

  useEffect(() => {
    getLocalTimer();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      getQBStatuses();
    }, 300000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    clearModalBackdrop();
  }, [location]);

  return (
    <>
      <main className="container py-4 flex-shrink-0" id="main-layout">
        {children}
      </main>
      <QBUnauthWarningModal qbUrl={qbAuthUrl} showModal={showModal} />
    </>
  );
};

export default MainLayout;
