import { useEffect, useState } from "react";
import { useBaseAction, useUserRole } from "hooks/core/index";
import {
  ICustomReactSelectOption,
  ISelectOption,
} from "core/types/elements/ICustomReactSelectOption";
import { UserPermissions } from "../../core/types/enums/PermissionsEnum";

type TProps = {
  fetchOnLoad: boolean;
  url: string;
  filter?: string;
  expand?: string;
  filterParam?: string;
  orderby?: string;
};

const useSelectOptions = <T extends ISelectOption>({
  fetchOnLoad,
  url,
  filter,
  expand,
  filterParam,
  orderby,
}: TProps) => {
  const { request } = useBaseAction();
  const [selectOptions, setSelectOptions] = useState<ICustomReactSelectOption[]>(
    [] as ICustomReactSelectOption[],
  );
  const { permissionGranted } = useUserRole();
  const canManage = permissionGranted([UserPermissions["WorkOrder.Manage"]]);

  const difinitionOptionKey = (item: T): string => {
    if (Object.prototype.hasOwnProperty.call(item, "tag") && item?.name) {
      return `${item?.name} ${item?.tag}`;
    }

    if (Object.prototype.hasOwnProperty.call(item, "type") && item?.name) {
      return `${item?.name}, Description: ${item?.description}, Type: ${item?.type}, Sku: ${
        item?.sku
      }${canManage ? `, Price: ${item?.unitPrice}` : ""}`;
    }

    if (Object.prototype.hasOwnProperty.call(item, "name") && item?.name) {
      return item.name;
    }

    if (Object.prototype.hasOwnProperty.call(item, "firstName")) {
      return `${item?.firstName} ${item?.lastName} ${item?.jobTitle ? `, ${item?.jobTitle}` : ""}`;
    }

    return "Invalid";
  };

  const mappingOptions = (data: T[]): ICustomReactSelectOption[] => {
    return (
      data.map((item: T) =>
        item.equipmentModel
          ? {
              value: String(item?.id),
              label: difinitionOptionKey(item),
              extraData: item.equipmentModel,
            }
          : {
              value: String(item?.id),
              label: difinitionOptionKey(item),
            },
      ) || []
    );
  };

  const getOptions = async (requestFilter?: string, useLoader = true) => {
    const data = (await request({
      method: "GET",
      url,
      useLoader,
      params: {
        $orderby: orderby || null,
        $filter:
          filterParam ||
          `isDeleted eq false${filter ? ` and ${filter}` : ""} ${
            requestFilter ? ` and ${requestFilter}` : ""
          }`,
        $expand: expand || null,
      },
    })) as T[];
    if (Array.isArray(data)) setSelectOptions(mappingOptions(data));
  };

  useEffect(() => {
    if (fetchOnLoad) getOptions();
  }, []);

  return {
    getOptions,
    selectOptions,
  };
};

export default useSelectOptions;
