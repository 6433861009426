import React, { FC } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import AccessDeniedRoute from "screens/common/AccessDeniedRoute";
import { useAuth } from "hooks/core/index";
import { useAppSelector } from "core/helpers/index";
import MainLayout from "components/common/Layout/MainLayout";
import { UserPermissions } from "core/types/enums/PermissionsEnum";

type TProps = {
  // eslint-disable-next-line react/no-unused-prop-types
  roles?: string[];
  allowedPermissions?: UserPermissions[];
};

const ProtectedRoute: FC<TProps> = ({ allowedPermissions }) => {
  const { currentUser } = useAppSelector((state) => state.app);
  const { userCanViewRoute } = useAuth();
  const userPermissions = currentUser?.rolePermissions || undefined;
  const location = useLocation();

  const isAuthenticated = !!currentUser;
  const userHasRequiredRole = currentUser
    ? userCanViewRoute(allowedPermissions, userPermissions)
    : false;

  if (isAuthenticated && userHasRequiredRole)
    return (
      <MainLayout>
        <Outlet />
      </MainLayout>
    );

  if (isAuthenticated && !userHasRequiredRole)
    return (
      <MainLayout>
        <AccessDeniedRoute />
      </MainLayout>
    );

  return <Navigate to="/signin" state={{ redirectTo: location }} />;
};

export default ProtectedRoute;
