const useLSActions = () => {
  const setToLs = <T,>(key: string, value: T): Promise<void> => {
    return new Promise((resolve) => {
      localStorage.setItem(key, JSON.stringify(value));
      resolve();
    });
  };

  const getFromLs = <T,>(key: string): Promise<T | null> => {
    return new Promise((resolve) => {
      const result = localStorage.getItem(key);
      resolve(result ? (JSON.parse(result) as T) : null);
    });
  };

  const removeItemFromLs = (key: string) => {
    localStorage.removeItem(key);
  };

  const defineCustomError = (instance: string) => {
    switch (instance) {
      case "WorkOrderExpenseItemIdMissingExcpetion":
        return "Technician doesn't have specified rates. Requires configuration.";
      case "CreationFailedException":
        return "Technician doesn't have specified rates. Requires configuration.";
      default:
        return "";
    }
  };

  return {
    setToLs,
    getFromLs,
    removeItemFromLs,
    defineCustomError,
  };
};

export default useLSActions;
