import { Buffer } from "buffer";
import { ITokenPayload } from "core/types/base/IToken";

const useToken = () => {
  const decodeBase64 = (data: string) => Buffer.from(data, "base64").toString("ascii");

  const accessTokenExpired = (exp: number) => new Date().getTime() >= exp * 1000;

  const parseToken = (token: string): ITokenPayload => {
    let payload = "";
    let tokenData: ITokenPayload = {} as ITokenPayload;
    try {
      payload = token.split(".")[1];
      tokenData = payload ? JSON.parse(decodeBase64(payload)) : "";
    } catch (error) {
      throw new Error(error as string);
    }
    return tokenData;
  };

  return {
    accessTokenExpired,
    parseToken,
  };
};

export default useToken;
