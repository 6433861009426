import { ICompany } from "core/types/entities";
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import type TDataState from "./type";

const initialState: TDataState = {
  currentCompany: null,
};

export const dataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    setCurrentCompany: (state: TDataState, action: PayloadAction<ICompany>) => {
      state.currentCompany = action.payload;
    },
  },
});

// Actions
export const { setCurrentCompany } = dataSlice.actions;

export default dataSlice.reducer;
