import { useEffect, useState } from "react";
import queryString from "query-string";
import { useLocation } from "react-router-dom";

const useQuery = () => {
  const { search } = useLocation();
  const [queryParams, setQueryParams] = useState<any>({});

  useEffect(() => {
    setQueryParams(queryString.parse(search) || {});
  }, []);

  return {
    queryParams,
  };
};

export default useQuery;
