const cancelMessage = "ARE YOU SURE THAT YOU WANT TO CANCEL WORK ORDER?";
const lostQuickBooks = "You lost a connection with QuickBooks";
const cancelAppointmentMessage = "ARE YOU SURE THAT YOU WANT TO CANCEL APPOINTMENT?";
const deleteAppointmentMessage = "ARE YOU SURE THAT YOU WANT TO  APPOINTMENT?";
const deleteeExpenseMessage = "ARE YOU SURE THAT YOU WANT TO DELETE EXPENSE?";
const deleteeRangeMessage = "ARE YOU SURE THAT YOU WANT TO DELETE RANGE?";
const deleteeVehicleMessage = "ARE YOU SURE THAT YOU WANT TO DELETE VEHICLE?";
const deleteeEquipmentMessage = "ARE YOU SURE THAT YOU WANT TO DELETE EQUIPMENT?";
const deleteeContactMessage = "ARE YOU SURE THAT YOU WANT TO DELETE CONTACT?";
const deleteeTechMessage = "ARE YOU SURE THAT YOU WANT TO DELETE TECHNICIAN?";
const deleteeNoteMessage = "ARE YOU SURE THAT YOU WANT TO DELETE NOTE?";
const deleteeSignatureMessage = "ARE YOU SURE THAT YOU WANT TO DELETE SIGNATURE?";
const completeWorkOrderMessage = "ARE YOU SURE THAT YOU WANT TO COMPLETE WORK ORDER?";

const insteadError = ["WorkOrderExpenseItemIdMissingExcpetion", "CreationFailedException"];

export {
  cancelMessage,
  lostQuickBooks,
  cancelAppointmentMessage,
  deleteAppointmentMessage,
  deleteeExpenseMessage,
  deleteeRangeMessage,
  deleteeVehicleMessage,
  deleteeEquipmentMessage,
  deleteeContactMessage,
  deleteeNoteMessage,
  deleteeSignatureMessage,
  completeWorkOrderMessage,
  insteadError,
  deleteeTechMessage,
};
