/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import TFilterState from "./type";

const initialState: TFilterState = {
  applied: true,
};

export const dataSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    setFilterApplied: (state: TFilterState, action: PayloadAction<boolean>) => {
      state.applied = action.payload;
    },
  },
});

// Actions
export const { setFilterApplied } = dataSlice.actions;

export default dataSlice.reducer;
