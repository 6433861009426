import { IBaseModalProps } from "core/types/elements/IModal";
import React, { FC } from "react";
import BaseModal from "components/common/BaseModal/BaseModal";
import { lostQuickBooks } from "constants/messages";

interface IProps extends IBaseModalProps<string> {
  qbUrl: string;
}

const QBUnauthWarningModal: FC<IProps> = ({ onSave, showModal, qbUrl }) => {
  const modalBody = () => (
    <div className="d-flex justify-content-center flex-column py-4">
      <h5 className="text-center mb-4">You must to sigin in. Please click on button below:</h5>
      <a className="btn btn-primary w-25 d-block mx-auto" href={qbUrl}>
        Sing in into QuickBooks
      </a>
    </div>
  );

  return (
    <BaseModal
      modalTitle={lostQuickBooks}
      showModal={showModal}
      onSave={onSave}
      modalBody={modalBody()}
      disableCloseActions
    />
  );
};

export default QBUnauthWarningModal;
