import React, { FC } from "react";
import { Outlet } from "react-router-dom";
import { useAppSelector } from "core/helpers";
// import Footer from "../Footer/Footer";
// import Navbar from "../Navbar/Navbar";
import AppLoader from "../AppLoader/AppLoader";

type TProps = {};

const AuthLayout: FC<TProps> = () => {
  const { showLoader } = useAppSelector((state) => state.app);
  return (
    <>
      {/* <Navbar /> */}
      <main className="container py-5 flex-shrink-0 m-auto">
        <Outlet />
      </main>
      {showLoader && <AppLoader />}
      {/* <Footer /> */}
    </>
  );
};

export default AuthLayout;
