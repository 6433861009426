import { useAppSelector } from "core/helpers/index";
import { IBaseNotifications } from "core/types/base/index";
import React, { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function AppNotification() {
  const { notification } = useAppSelector((state) => state.app);

  const notify = (_notification: IBaseNotifications) => {
    switch (_notification.type) {
      case "success":
        return toast.success(_notification.message);
      case "error":
        return toast.error(_notification.message);
      default:
        return toast(_notification.message);
    }
  };

  useEffect(() => {
    if (notification.message) notify(notification);
  }, [notification]);

  return <ToastContainer />;
}

export default AppNotification;
