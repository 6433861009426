import { Component, FunctionComponent, ReactElement } from "react";

function ErrorBoundaryFallback(): ReactElement<
  unknown,
  string | typeof Component | FunctionComponent<{}>
> {
  return (
    <div className="row">
      <div className="col-md-8 mx-auto">
        <div className="alert alert-dander" role="alert">
          <h4 className="alert-heading">Oops! Something is wrong.</h4>
          <p>
            Brace yourself till we get the error fixed. You may also refresh the page or try again
            later
          </p>
          <hr />
        </div>
      </div>
    </div>
  );
}

export default ErrorBoundaryFallback;
