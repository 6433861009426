import { useCallback, useEffect, useRef } from "react";

type TProps = {
  callBack?: (event: MouseEvent) => void;
};

const useOnClickOutside = <T extends HTMLElement>({ callBack }: TProps) => {
  const elementRef = useRef<T>(null);

  const hookHandler = useCallback(
    (event: MouseEvent) => {
      if (!elementRef || !elementRef.current || !event) return;
      // Do nothing if clicking ref's element or descendent elements
      if (elementRef.current.contains(event.target as any)) {
        return;
      }
      if (callBack) callBack(event);
    },
    [elementRef, callBack],
  );

  useEffect(() => {
    document.addEventListener("mousedown", hookHandler);
    return () => {
      document.removeEventListener("mousedown", hookHandler);
    };
  }, [elementRef, hookHandler]);

  return {
    elementRef,
    hookHandler,
  };
};

export default useOnClickOutside;
