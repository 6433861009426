import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { IBaseListResponse } from "core/types/base";
import useBaseAction from "./useBaseActions";

type TProps = {
  baseUrl?: string;
  orderby?: string;
  expand?: string;
  filter?: string;
};

export type TFetchListProps = {
  useLoader?: boolean;
  filter?: string;
  page?: number;
  isClear?: boolean;
  pageSize?: number;
};

export const defaultPageSize = 12;

const useInfinityList = <T extends unknown>({
  baseUrl,
  orderby,
  expand,
  filter: defaultFilterQuery,
}: TProps) => {
  const { request } = useBaseAction();
  const [listData, setListData] = useState<T[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [fetching, setFetching] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const [storedFilterQuery, setStoredFilterQuery] = useState<string>("");

  const queryParams = searchParams.get("filter") || null;

  const fetchListData = async ({
    useLoader = false,
    filter: appliedFilterQuery = "",
    page,
    isClear,
    pageSize,
  }: TFetchListProps) => {
    if (fetching) {
      return;
    }
    if (appliedFilterQuery) {
      setStoredFilterQuery(appliedFilterQuery);
    }

    setFetching(true);
    const currentFilterQuery = isClear ? "" : storedFilterQuery || appliedFilterQuery;
    const filterQueryWithParams = `${defaultFilterQuery || ""}${
      currentFilterQuery ? ` and ${currentFilterQuery}` : ""
    }${queryParams && !isClear ? ` and ${queryParams}` : ""}`;

    const response = (await request({
      method: "GET",
      url: `${baseUrl}`,
      params: {
        $orderby: orderby || null,
        $count: true,
        $top: pageSize || defaultPageSize,
        // eslint-disable-next-line no-nested-ternary
        $skip: page !== undefined ? page : currentPage > 0 ? currentPage * defaultPageSize : 0,
        $filter: filterQueryWithParams || null,
        $expand: expand || null,
      },
      useLoader,
    })) as IBaseListResponse<T>;

    const { items, count } = response;
    // eslint-disable-next-line no-nested-ternary
    const nextPage = pageSize
      ? pageSize / defaultPageSize
      : page !== undefined
      ? page + 1
      : currentPage + 1;
    setListData(page === 0 || currentPage === 0 ? items : [...listData, ...items]);
    setTotalCount(count);
    setCurrentPage(nextPage);
    setHasMore(nextPage * defaultPageSize < count);
    setFetching(false);
  };
  // }, [baseUrl, currentPage, fetching, listData, request]);

  const filterList = (id: number) => {
    const filetedList = listData.filter((data: any) => data?.id !== id);
    setListData(filetedList);
    setTotalCount((prevState) => prevState - 1);
  };

  const changeItem = (id: number, key: string, value: any) => {
    const changedList = listData.map((data: any) => {
      if (data?.id === id) {
        return { ...data, [key]: value };
      }
      return { ...data };
    });
    setListData(changedList);
  };

  return {
    fetchListData,
    listData,
    totalCount,
    hasMore,
    fetching,
    currentPage,
    filterList,
    changeItem,
  };
};

export default useInfinityList;
