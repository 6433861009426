import React, { FC } from "react";
import "./Footer.scss";

interface FooterProps {}

const Footer: FC<FooterProps> = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="footer mt-auto">
      <div className="container">
        <div className="col-12 text-center">
          <span className="text-muted">
            © {currentYear}, All Right Reserved - Kustom Printing Products
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
