import { Modal } from "bootstrap";
import { useAppDispatch, useAppSelector } from "core/helpers";
import { IBaseModalProps } from "core/types/elements/IModal";
import useOnClickOutside from "hooks/core/useOnClickOutside";
import React, { FC, ReactNode, useEffect, useRef, useState } from "react";
import { setFilterApplied } from "store/slices/filterSlicer";
import "./BaseModal.scss";
import ModalLoader from "../AppLoader/ModalLoader";

interface IProps extends IBaseModalProps<any> {
  modalBody?: ReactNode;
  cancelActionBtnText?: string;
  successBtnText?: string;
  disableCloseActions?: boolean;
  className?: string;
  fullScreen?: boolean;
  modalClass?: string;
  deleteActionBtnText?: string;
  onDelete?: () => void;
  showDelete?: boolean;
  isDisabledSubmit?: boolean;
}

const BaseModal: FC<IProps> = ({
  showDelete,
  deleteActionBtnText,
  onDelete,
  onClose,
  showModal,
  modalTitle,
  modalBody,
  onSave,
  onCancel,
  successBtnText,
  cancelActionBtnText,
  disableCloseActions,
  className,
  fullScreen,
  modalClass,
  isDisabledSubmit,
}) => {
  const [modal, setModal] = useState<Modal | null>(null);
  const baseModal = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const { showLoader, error } = useAppSelector((state) => state.app);

  const onButtonClose = () => {
    if (onClose) onClose();
    modal?.hide();
  };

  const { elementRef } = useOnClickOutside<HTMLDivElement>({
    callBack: () => !disableCloseActions && showModal && onButtonClose(),
  });

  useEffect(() => {
    return () => {
      if (onClose) onClose();
      modal?.hide();
    };
  }, []);

  const initModal = () => {
    if (!showModal || !baseModal?.current) return;
    const modalInstance = new Modal(baseModal.current);
    modalInstance?.show();
    setModal(modalInstance);
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    showModal ? initModal() : modal?.hide();
  }, [showModal]);

  useEffect(() => {
    if ((error as any)?.status === 0) {
      modal?.hide();
    }
  }, [error]);

  return showModal ? (
    <div
      className={`modal fade modal--base ${className || ""}`}
      ref={baseModal}
      tabIndex={-1}
      data-bs-backdrop={disableCloseActions ? "static" : "modal"}
      aria-labelledby="baseModalLabel"
    >
      <div
        className={`modal-dialog modal-dialog-centered modal-lg ${
          fullScreen ? "modal-fullscreen" : "modal-lg"
        } ${modalClass ?? ""}`}
        ref={elementRef}
      >
        <div className="modal-content">
          <div className="modal-header position-relative">
            {modalTitle && (
              <h4
                className="modal-title"
                id="baseModalLabel"
                style={
                  !modalBody
                    ? {
                        paddingTop: "50px",
                        paddingBottom: "50px",
                        margin: "auto",
                        textAlign: "center",
                      }
                    : {}
                }
              >
                {modalTitle}
              </h4>
            )}
            {!disableCloseActions && (
              <button
                type="button"
                className="btn-close position-absolute"
                onClick={onButtonClose}
                aria-label="Close"
              />
            )}
          </div>
          {modalBody && <div className="modal-body">{modalBody}</div>}
          {onCancel && onSave && (
            <div className={`modal-footer ${showDelete ? "justify-content-between" : ""}`}>
              {showDelete && (
                <button
                  type="button"
                  className="btn btn-danger text-white"
                  data-bs-dismiss="modal"
                  onClick={() => onDelete && onDelete()}
                >
                  {deleteActionBtnText}
                </button>
              )}
              <div className="d-flex gap-2">
                {onCancel && (
                  <button
                    type="button"
                    className="btn btn-danger text-white"
                    data-bs-dismiss="modal"
                    onClick={() => onCancel()}
                  >
                    {cancelActionBtnText || "No"}
                  </button>
                )}
                {onSave && (
                  <button
                    type="button"
                    className="btn btn-success text-white d-flex align-items-center justify-content-center"
                    disabled={showLoader || isDisabledSubmit}
                    onClick={() => {
                      onSave();
                      dispatch(setFilterApplied(false));
                    }}
                  >
                    {showLoader && <ModalLoader />}
                    <span className="ms-2">{successBtnText || "Yes"}</span>
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  ) : null;
};

export default BaseModal;
