import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const initValidation = (shape: any) => {
  return yup.object().shape(shape).required();
};

// const signInSchema = initValidation({
//   email: yup.string().email().required(),
//   password: yup.string().required(),
// });

// const forgotPasswordSchema = initValidation({
//   email: yup.string().email().required(),
// });

const validationResolver = (schema: any) => yupResolver(schema);

const invalidClassName = "is-invalid";
// const validClassName = "is-valid";

export const toggleInvalidInput = (invalid: boolean) => (invalid ? invalidClassName : "");

export const maskOnlyNumbers = (value: string) => {
  let cleanedValue = value.replace(/[^\d()+-]/g, "");

  cleanedValue = cleanedValue.startsWith("+") ? cleanedValue : `+${cleanedValue}`;

  const lengthLimit = cleanedValue.includes("(") || cleanedValue.includes(")") ? 15 : 14;

  const finalValue =
    cleanedValue.length > lengthLimit ? cleanedValue.slice(0, lengthLimit) : cleanedValue;

  return finalValue;
};

export const replaceLetters = (inputString: string) => inputString.replace(/[a-zA-Z]/g, "");

const validationPhone = (val: string | undefined): boolean => {
  if (!val) return false;
  const clearMask = val && val.replace(/-|_|[{()}]/g, "").length;
  return clearMask > 11;
};

export { validationResolver, initValidation, validationPhone };
